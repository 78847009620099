import React from "react"
import Layout from "../../../template/Layout"
import Head from "../../../head";
import styled from "styled-components"
import Img from 'gatsby-image';
import { RESPONSIVE_STYLES, RECRUIT_PAGE_STYLES } from "../../../AppConst";
import { ogpUrl } from "../../../utlis/ImageUtil"

import { SpWrapper } from "../../common/SpWrapper"
import { SectionTitle } from "../../common/SectionTitle"
import { Props as BreadcrumbsData } from "../../common/Breadcrumbs"
import { PickUpWelfareArticleList } from "../common/PickUpWelfareArticleList";
import { Welfares } from "./Welfares";


const Wrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`

const HeaderContainer = styled.div`
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 160px 0;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 80px 0;
  }
`

const BlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 160px 20px;
  background-color: #E9E9E9;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 32px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    padding: 64px 16px;
  }
`

const ArticlesWrapper = styled.div`
  display: flex;
  gap: 36px;
  width: 100%;
  max-width: 1080px;
`

type Props = {
  headerImg?: any;
  breadcrumbsData: BreadcrumbsData["values"];
}

export const Welfare: React.VFC<Props> = (props) => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="福利厚生| 採用情報 | ネクストライブ株式会社"
        description="ネクストライブ株式会社の福利厚生の紹介ページです。"
        img={ogpUrl(process.env.NODE_ENV, props.headerImg)}
      />
      <Wrapper>
        <SectionTitle title="welfare" subTitle="福利厚生"/>
        <HeaderContainer>
          <Img fluid={props.headerImg} alt="福利厚生"/>
        </HeaderContainer>
        <Container>
          <SpWrapper>
            <Welfares />
          </SpWrapper>
        </Container>

        <BlogContainer>
          <ArticlesWrapper>
            <PickUpWelfareArticleList />
          </ArticlesWrapper>
        </BlogContainer>
      </Wrapper>
    </Layout>
  )
}
