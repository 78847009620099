module.exports = {
  "慶弔": [
    {
      icon: "wedding.svg",
      title: "結婚休暇",
      description: "結婚式や新婚旅行、結婚に伴う準備や手続きのために取得できる特別休暇",
    },
    {
      icon: "condolence.svg",
      title: "忌引き休暇",
      description: "親族が亡くなった際に取得できる特別休暇",
    },
  ],
  "妊娠・出産": [
    {
      icon: "maternity.svg",
      title: "マタニティ休暇",
      description: "妊娠や出産に伴う休養、通院、妊婦のサポートのために取得できる特別休暇<br />妊娠期間中10日間の給与の全額を会社が保障",
    },
    {
      icon: "maternity.svg",
      title: "マタニティ休業",
      description: "妊娠により就業が困難な場合に一定期間休業できる制度",
    },
    {
      icon: "maternity_time.svg",
      title: "マタニティ短時間勤務",
      description: "妊娠により所定労働時間での就業が困難な場合に就業時間を短縮できる制度",
    },
    {
      icon: "maternity_leave.svg",
      title: "産前産後休業",
      description: "産前6週間前から産後8週間まで取得できる休業制度",
    },
    {
      icon: "welcome_baby.svg",
      title: "ウェルカムベビー休暇",
      description: "配偶者の出産立会いや入退院のサポート、出産後の手続きのために取得できる特別休暇",
    },
  ],
  "育児": [
    {
      icon: "child_leave.svg",
      title: "こども休暇",
      description: "こどもの看護や通院、学校行事等のために取得できる特別休暇<br />こども1人あたり年3日間の給与の全額を会社が保障",
    },
    {
      icon: "childcare_leave.svg",
      title: "育児休業",
      description: "1歳未満のこどもを養育するための休業制度",
    },
    {
      icon: "postpartum_parental_leave.svg",
      title: "産後パパ育休",
      description: "男性がこどもの出生日から8週間以内に、最長4週間の育休をとれる制度",
    },
    {
      icon: "papa_maternity_leave_plus.svg",
      title: "パパ・ママ育休プラス",
      description: "育休をこどもが1歳2カ月になるまで延長できる制度",
    },
    {
      icon: "shorter_working_hours_for_childcare.svg",
      title: "育児短時間勤務",
      description: "育児により所定労働時間での就業が困難な場合就業時間を短縮できる制度",
    },
    {
      icon: "balancing_work_and_childcare.svg",
      title: "育児と仕事の両立プランニング制度",
      description: "育児と仕事の両立やキャリアアップ形成等を会社と共に考え、サポートする制度",
    },
  ],
  "介護": [
    {
      icon: "nursing_care_leave.svg",
      title: "介護休業",
      description: "要介護状態の家族を介護するための休業制度",
    },
    {
      icon: "nursing_care_leave.svg",
      title: "介護短時間勤務",
      description: "介護により所定労働時間での就業が困難な場合に就業時間を短縮できる制度",
    },
  ],
  "セルフケア": [
    {
      icon: "f_leave.svg",
      title: "Ｆ休暇",
      description: "女性特有の体調不良や婦人科治療のために取得できる特別休暇<br />年3日間まで給与の全額を会社が保障",
    },
    {
      icon: "regular_health_checkups.svg",
      title: "定期健康診断",
      description: "1年に1回の定期健康診断",
    },
    {
      icon: "subsidized_influenza_vaccination.svg",
      title: "インフルエンザ予防接種補助",
      description: "インフルエンザ予防接種費用を補助",
    },
    {
      icon: "vaccine_leave.svg",
      title: "ワクチン休暇",
      description: "新型コロナウイルスのワクチン接種のために取得できる特別休暇",
    },
  ],
  "イベント": [
    {
      icon: "welcome_lunch.svg",
      title: "ウェルカムランチ",
      description: "入社後1か月間行われる少人数制の歓迎ランチ会",
    },
    {
      icon: "tenichi_festival.svg",
      title: "天一フェス",
      description: "毎年10月1日から行われる天下一品祭り期間中、会社が食事代を負担する制度",
    },
    {
      icon: "field_trip.svg",
      title: "遠足",
      description: "日帰りで自由なプランを企画し、全力で楽しむイベント",
    },
    {
      icon: "good_and_new.svg",
      title: "Good＆New",
      description: "24時間以内にあった「良かったこと」や「新しい発見」をシェアする朝会",
    },
    {
      icon: "lt_meeting.svg",
      title: "LT会",
      description: "メンバーが主体となって開催される短いプレゼンテーション会",
    },
  ],
  "キャリア支援": [
    {
      icon: "link_vision_career_support_system.svg",
      title: "LINK VISION（キャリア支援制度）",
      description: "メンバー個人の挑戦と、会社のビジョンの接点を繋ぎ、個と組織が本質的に成長していくためのキャリア支援制度",
    },
    {
      icon: "qualification_incentive.svg",
      title: "資格取得奨励金",
      description: "対象となる資格を取得した際、奨励金と受験費用を支給する制度",
    },
  ],
  "働き方・ワークスタイル": [
    {
      icon: "remote_work.svg",
      title: "リモートワーク",
      description: "在宅勤務ができる制度",
    },
    {
      icon: "slide_work.svg",
      title: "スライドワーク",
      description: "出勤時間を30分単位でスライドできる制度",
    },
    {
      icon: "no_overtime_day.svg",
      title: "ノー残業デー",
      description: "毎週水曜に導入<br />自分の「時間」を大切にし、食事や趣味、家族との時間を楽しむための制度",
    },
  ],
  "バケーション": [
    {
      icon: "adventure_leave.svg",
      title: "冒険休暇",
      description: "人生で経験したことがないことに挑戦するための特別休暇（年1日）<br />給与の全額を会社が保障",
    },
    {
      icon: "year_end_and_new_year_vacations.svg",
      title: "年末年始休暇",
      description: "年末年始に3日間取得できる特別休暇",
    },
    {
      icon: "summer_vacation.svg",
      title: "夏季休暇",
      description: "8月～9月の期間に3日間取得できる特別休暇",
    },
  ],
  "その他": [
    //{
    //  icon: "benefits_service_perk.svg",
    //  title: "福利厚生サービス（Perk）",
    //  description: "400以上の豊富なサービスが特別価格で利用できる優待サービス",
    //},
    {
      icon: "next_coin_system_in_house_currency.svg",
      title: "ネクストコイン制度（社内通貨）",
      description: "「ありがとう」をメンバー間で贈り合い、もらったコインは賞与に還元されるダブルで幸せになれる制度",
    },
  ],
  "手当": [
    {
      icon: "commuting_allowance.svg",
      title: "通勤手当",
      description: "公共交通機関を利用した通勤交通費を支給する手当（月3万円まで）",
    },
    {
      icon: "remote_work_allowance.svg",
      title: "リモートワーク手当",
      description: "在宅勤務に係る経費を支給する手当",
    },
    {
      icon: "bicycle_walking_to_work_allowance.svg",
      title: "自転車徒歩通勤手当",
      description: "自転車や徒歩のみで通勤する場合に支給する手当",
    },
    {
      icon: "referral_allowance.svg",
      title: "リファラル手当",
      description: "社員の紹介で採用が決まった場合に支給する手当",
    },
  ],
}
