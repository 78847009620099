import React from "react"
import styled from "styled-components"

import { RESPONSIVE_STYLES } from "../../../AppConst";
const data = require('./data.js');

const Groups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 40px;
  }
`

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 16px;
  }
`

const GroupTitle = styled.h3`
  width: 100%;
  margin: 0;
  padding: 0;

  color: #222;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 1.6px;
  text-transform: capitalize;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 344px;
  height: 248px;
  padding: 16px 24px;
  box-sizing: border-box;

  background: #F4F4F4;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    height: auto;
    padding: 16px;
  }

  & > span {
    margin: 0;
    padding: 0;

    color: #61B1C1;

    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 1.6px;
    text-transform: capitalize;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      gap: 8px;
    }

    & > h4 {
      display: flex;
      align-items: center;
      gap: 8px;

      width: 100%;
      margin: 0;
      padding: 0;

      color: #222;

      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 1px;
      @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.7px;
      }

      & > img {
        width: 30px;
        height: auto;
        @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
          width: 21px;
        }
      }
    }

    & > p {
      width: 100%;
      margin: 0;
      padding: 0;

      color: #222;
      text-align: justify;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 200% */
      letter-spacing: 0.7px;
      text-align: left;
      @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
        font-size: 12px;
        line-height: 24px; /* 200% */
        letter-spacing: 0.6px;
      }
    }
  }
`

type Props = {
}
export const Welfares: React.VFC<Props> = () => {
  let count = 1;

  return (
    <Groups>
      {
        Object.keys(data).map((key) => {
          return (
            <Group key={key}>
              <GroupTitle>{key}</GroupTitle>
              {
                data[key].map((content, j) => {
                  return (
                    <Card key={content.title}>
                      <span>{(count++).toString().padStart(2, '0')}</span>
                      <div>
                        <h4><img src={require(`../../../images/recruit/welfare/${content.icon}`).default} />{content.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: content.description }} />
                      </div>
                    </Card>
                  )
                })
              }
            </Group>
          )
        }
        )}
    </Groups>
  )
}
